<template>
  <div>
    <transition name="vload">
      <div v-show="isLoading" class="Loader">
        <div class="sk-cube-grid">
          <div class="sk-cube sk-cube1"></div>
          <div class="sk-cube sk-cube2"></div>
          <div class="sk-cube sk-cube3"></div>
          <div class="sk-cube sk-cube4"></div>
          <div class="sk-cube sk-cube5"></div>
          <div class="sk-cube sk-cube6"></div>
          <div class="sk-cube sk-cube7"></div>
          <div class="sk-cube sk-cube8"></div>
          <div class="sk-cube sk-cube9"></div>
        </div>
        <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
      </div>
    </transition>
    <div id="main-wrapper">
      <Header></Header>
      <div class="header-margin"></div>
      <div v-if="entry">
        <BreadCrumb :pages="[{path: `/article`, name: `SESノウハウ`}]" :current-name="entry.title"></BreadCrumb>
        <section class="p-top-0">
          <div class="container">
            <div class="row">

              <!-- Blog Detail -->
              <div v-if="entry" class="col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="blog-details single-post-item format-standard">
                  <div class="post-details">
                    <h1 class="post-title">{{entry.title}}</h1>
                    <div class="post-featured-img">
                      <!--<img class="img-fluid article-img" :src="entry.thumbnail" :alt="entry.alt">-->
                      <picture class="img-fluid w-100">
                        <source media="(min-width: 768px)" type="image/avif" :srcset="entry.thumb_avif" width="333" height="187">
                        <source type="image/webp" :srcset="entry.thumb_webp" width="333" height="187">
                        <img :src="entry.thumbnail" class="img-fluid article-img" :alt="entry.alt" width="333" height="187">
                      </picture>
                    </div>

                    <div class="post-top-meta">
                      <ul class="meta-comment-tag">
                        <li>公開日：{{entry.createdAt}}</li>
                        <li>更新日：{{entry.updatedAt}}</li>
                      </ul>
                    </div>
                    <!--リンク設定-->
                    <!--<div class="link-temp">
                      <a href="">
                        <img src="assets/img/companyList_.png" class="link-img" >
                        <div class="link-text-frame">
                          <div class="link-text-group">
                            <p class="link-title">SES契約（準委任契約）で現場から残業指示。どう対応するのが正しい？</p>
                            <p class="link-text">システムエンジニアをされている方は「SES契約」という言葉を良く</p>
                          </div>
                        </div>
                      </a>
                    </div>-->
                    <div ref="contentBody" class="contentBody" v-html="toHtml(entry.body)"></div>
                    <!--筆者欄-->
                    <h3 class="author-title">この記事を書いた人</h3>
                    <div class="link-temp author-temp">
                      <div>
                        <img :src="entry.author ? entry.author.fields.image.fields.file.url : ''" class="author-img" alt="この記事を書いた人アイコン" width="120" height="125" loading="lazy">
                        <div class="link-text-frame author-text-frame">
                          <div class="link-text-group">
                            <p class="link-title">{{entry.author ? entry.author.fields.name : ''}}</p>
                            <p class="author-text">{{entry.author ? entry.author.fields.description : ''}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="home-content">
                  <h2 class="connection-article-title">関連記事</h2>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-12" v-for="item in tagEntries" :key="item.id">
                        <div class="blog-wrap-grid">
                          <div class="blog-thumb">
                            <!--<router-link :to="`/article/${encodeURI(item.englishTitle)}`"><img :src="item.thumbnail" class="img-fluid" :alt="item.alt"></router-link>-->
                            <router-link :to="`/article/${encodeURI(item.englishTitle)}`">
                              <picture class="img-fluid w-100">
                                <source media="(min-width: 768px)" type="image/avif" :srcset="item.thumb_avif" width="333" height="187">
                                <source type="image/webp" :srcset="item.thumb_webp" width="333" height="187">
                                <img :src="item.thumbnail" class="img-fluid w-100" :alt="item.alt" width="333" height="187">
                              </picture>
                            </router-link>
                          </div>

                          <div class="blog-info">
                            <span class="post-date"><i class="fa fa-calendar"></i>{{item.createdAt}}</span>
                          </div>

                          <div class="blog-body">
                            <h2 class="bl-title"><router-link :to="`/article/${encodeURI(item.englishTitle)}`">{{item.title}}</router-link></h2>
                          </div>
                          <div class="blog-cates">
                            <ul>
                              <li v-for="tag in item.tags" :key="item.id + tag.sys.id"><router-link :to="{ path: '/article', query: { tag: encodeURI(tag.sys.id) }}" class="blog-cates-list">{{allTags[tag.sys.id]}}</router-link></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--<div class="home-more"><a>おすすめの記事をもっとみる</a></div>-->
                </div>
              </div>
              <side-area v-if="entry" :exists-menu-list="false" tag-title="この記事のタグ" :tags="tags"></side-area>
            </div>
          </div>
        </section>
      </div>
      　
            <!-- ========================== Blog Detail Elements ============================= -->
      <Footer></Footer>
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
  </div>
</template>
<script>
import Header from "@/views/components/Header";
import Footer from "@/views/components/Footer";
import moment from "moment";
import Vue from 'vue'
import {createContentfulClient} from "@/common/contentful";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import { BLOCKS } from '@contentful/rich-text-types'
import SideArea from "@/views/components/SideArea";
import BreadCrumb from "@/views/components/BreadCrumb";
import MixIn from "@/common/mixin";
export default {
  name: "ArticleDetail",
  components: {BreadCrumb, Footer, Header, SideArea},
  mixins: [MixIn],
  data: function() {
    return {
      entry: undefined,
      tagEntries: [],
      isLoading: true,
      tags: {},
      allTags: {}
    }
  },
  created: function () {
    if (!Vue.prototype.$contentfulClient) {
      Vue.prototype.$contentfulClient = createContentfulClient();
    }
    this.loadEntries();
  },
    watch: {
      // '$route.params': function () {
        // this.loadEntries();
      // }
    },
  updated: function () {
  },
  methods: {
    async loadEntries () {
      this.isLoading = true;
      if (!this.$route.params.englishTitle) {
        return;
      }
      const client = Vue.prototype.$contentfulClient;
      const query = {
        "content_type": "blog",
        "fields.englishTitle[in]": decodeURI(this.$route.params.englishTitle)
      };
      const entries = await client.getEntries(query);

      if (entries.items.length === 0) {
        this.$router.push({ path: `${this.$router.currentRoute.path}/error` });
        return;
      }
      const entry = entries.items[0];

      this.entry = {
        id: entry.sys.id,
        title: entry.fields.title,
        body: entry.fields.body,
        author: entry.fields.author,
        thumbnail: entry.fields.thumbnail ? entry.fields.thumbnail.fields.file.url : "",
        //alt: entry.fields.thumbnail ? entry.fields.thumbnail.fields.description : "",
        createdAt: moment(entry.sys.createdAt).format("YYYY/MM/DD"),
        updatedAt: moment(entry.sys.updatedAt).format("YYYY/MM/DD"),

        //add.2024.06
        alt: entry.fields.thumbnail ? entry.fields.title  : "",
        thumb_avif: entry.fields.thumbnail ? entry.fields.thumbnail.fields.file.url + '?fm=avif&q=80' : "" ,
        thumb_webp: entry.fields.thumbnail ? entry.fields.thumbnail.fields.file.url + '?fm=webp&q=80' : "" ,
        thumb_jpg: entry.fields.thumbnail ? entry.fields.thumbnail.fields.file.url + '?fm=jpg&fl=progressive&q=80' : "" ,
        thumb_w:entry.fields.thumbnail ? entry.fields.thumbnail.fields.file.details.image.width : "",
        thumb_h:entry.fields.thumbnail ? entry.fields.thumbnail.fields.file.details.image.height : "",
      };

      document.title = `SES MEDIA｜${this.entry.title}`;
      if (entry.fields.description) {
        document.querySelector('meta[name="description"]').setAttribute("content", entry.fields.description);
      }

      // const tags = await client.getTags({
      //   "sys.id[in]": entry.metadata.tags.map(tag => tag.sys.id).join()
      // });
      // this.tags = Object.fromEntries(tags.items.map(item => {
      //   return [item.sys.id, item.name]
      // }));

      const allTags = await client.getTags();
      this.allTags = Object.fromEntries(allTags.items.map(item => {
        return [item.sys.id, item.name]
      }));

      const tagIds = entry.metadata.tags.map(tag => tag.sys.id);
      this.tags = Object.fromEntries(tagIds.map(id => {
        return [id, this.allTags[id]];
      }));

      const tagQuery = {
        limit: 2,
        "content_type": "blog",
        order: '-sys.createdAt',
        "metadata.tags.sys.id[in]": tagIds.join()
      };
      const tagEntries = await client.getEntries(tagQuery);
      this.tagEntries = tagEntries.items.map(item => {
        return {
          id: item.sys.id,
          title: item.fields.title,
          englishTitle: item.fields.englishTitle,
          thumbnail: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url : "",
          //alt: item.fields.thumbnail ? item.fields.thumbnail.fields.description : "",
          tags: item.metadata.tags,
          createdAt: moment(item.sys.createdAt).format("YYYY/MM/DD"),

          //add.2024.06
          alt: item.fields.thumbnail ? item.fields.title  : "",
          thumb_avif: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=avif&q=50' : "" ,
          thumb_webp: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=webp&q=50' : "" ,
          thumb_jpg: item.fields.thumbnail ? item.fields.thumbnail.fields.file.url + '?fm=jpg&fl=progressive&q=50' : "" ,
          thumb_w:item.fields.thumbnail ? item.fields.thumbnail.fields.file.details.image.width : "",
          thumb_h:item.fields.thumbnail ? item.fields.thumbnail.fields.file.details.image.height : "",
        }
      });


      const tableLinks = this.$refs["contentBody"].getElementsByClassName("tableLink");

      for (const tableLink of tableLinks) {
        tableLink.addEventListener("click", () => {
          this.scrollElementById(encodeURI(tableLink.dataset.head));
        });
      }

      this.isLoading = false;
    },
    tableOfContents(body) {
      const headingTypes = [BLOCKS.HEADING_2]

      const headings = body.content.filter(item => headingTypes.includes(item.nodeType))

      const documentRoot = {
        nodeType: 'document',
        content: headings,
      }

      const options = {
        renderNode: {
          [BLOCKS.HEADING_2]: (node) => {
            return `<li><a class="tableLink" data-head="${node.content[0].value}">・${node.content[0].value}</a></li>`
          }
        }
      }

      return `<section class="padding-top-30 padding-bottom-30">
              <div class="container">
                <div class="row">
                  <div class="col-12 padding0">
                  <span class="table-of-contents-title">本記事の目次</span>
                      <ul class="table-of-contents table-of-contents-only">
                          <div class="space10"></div>
                              ${documentToHtmlString(documentRoot, options)}
                          <div class="space10"></div>
                      </ul>
                  </div>
                </div>
              </div>
            </section>`;
    },
    toHtml(body) {
      return documentToHtmlString(body, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
              //`<img class="image_setting" src="${fields.file.url}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}" loading="lazy" />`,
              `<img class="image_setting" src="${fields.file.url+ '?fm=jpg&fl=progressive&q=60'}" height="${fields.file.details.image.height}" width="${fields.file.details.image.width}" alt="${fields.description}" loading="lazy" />`,
          [BLOCKS.PARAGRAPH]: (node, next) =>{
            if (node.content[0].value === "[[table]]") {
              return this.tableOfContents(body);
            } else {
              const p = `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`;
              return p.replace(/\[\[strong]]/g, `<span class="strong-text">`).replace(/\[\[\/strong]]/g, `</span>`);
            }
          },
          [BLOCKS.EMBEDDED_ENTRY]: ({ data: { target } }) => {
            const fields = target.fields;
            if (target.sys.contentType.sys.id === "recruitButton") {
              return `<div class="link-temp">
                      <a target="_blank" href="${fields.url}">
                        <div class="link-text-frame">
                          <p class="link-text">${fields.recruitText}</p>
                        </div>
                      </a>
                    </div>`;
            }
            let url;
            let thumbnailUrl;
            let thumbnailDescription;
            let title;
            let detail;

            if (target.sys.contentType.sys.id === "linkFrame") {
              url = fields.url;
              thumbnailUrl = fields.thumbnail.fields.file.url;
              thumbnailDescription = fields.thumbnail.fields.description ? fields.thumbnail.fields.description : "";
              title = fields.title;
              detail = fields.detail;
            } else {
              if (target.sys.contentType.sys.id === "interview") {
                url = `/interview/${encodeURI(fields.englishTitle)}`;
              } else {
                url = `/article/${encodeURI(fields.englishTitle)}`;
              }
              thumbnailUrl = fields.thumbnail.fields.file.url;
              thumbnailDescription = fields.thumbnail.fields.description ? fields.thumbnail.fields.description : "";
              title = fields.title;
              detail = fields.description ? fields.description : "";
            }
            return `<div class="link-temp">
                      <a target="_blank" href="${url}">
                        <img src="${thumbnailUrl}" class="link-img" alt="${thumbnailDescription}">
                        <div class="link-text-frame">
                          <p class="link-title">${title}</p>
                          <p class="link-text">${detail}</p>
                        </div>
                      </a>
                    </div>`;
          },
          [BLOCKS.HEADING_2]: (node, next) => {
            return `<h2 id="${encodeURI(node.content[0].value)}">${next(node.content)}</h2>`
          }
        },
      })
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .vload-leave-active {
        transition: opacity 350ms;
    }
    .vload-leave-to{
        opacity: 0;
    }
    .contentBody >>> h2 {
      margin-top: 30px;
      margin-bottom: 30px;
      line-height: 1.5;
      padding: 15px 10px;
      letter-spacing: 0.05rem;
      font-size: 20px;
      font-weight: 700;
      border-bottom: #181D48 2px solid;
    }
    .contentBody >>> h3 {
      margin-top: 20px;
      font-size: 16px;
    }
    /**これをいじればh3の設定できる**/
    /**.contentBody >>> h3::before {
      background: #004aad;
      position: absolute;
      width: 5px;
      height: 20px;
      padding-top: 0;
      content: '';
      margin-top: 5px;
    }**/
    .contentBody >>> h4 {
      margin-top: 20px;
    }
    .contentBody >>> h5 {
      margin-top: 15px;
    }
    .contentBody >>> h6 {
      margin-top: 10px;
    }
    .contentBody >>> ul {
      padding: 0;
      margin-top: 10px;
    }
    .contentBody >>> li p {
      margin-top: 5px;
    }
    .contentBody >>> .tableLink {
      cursor: pointer;
      font-weight: 700;
    }
    .strong-text {
      --strong-color: #ffd814;
      background: repeating-linear-gradient(-45deg ,var(--strong-color),var(--strong-color) 2px,transparent 2px,transparent 4px) no-repeat 0 0.75em;
    }
</style>
